@import 'bootstrap/dist/css/bootstrap.min.css';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');
@import 'react-toastify/dist/ReactToastify.css';

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


@font-face {
  font-family: 'Hiragino Mincho Pro';
  src: url('fonts/HiraMinProN-W3-AlphaNum.eot') format('embedded-opentype');
  src: url('fonts/HiraMinProN-W3-AlphaNum.woff') format('woff'), url('fonts/HiraMinProN-W3-AlphaNum.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
 }

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 7px rgba(0,0,0,0); 
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 7px rgba(0,0,0,1); 
}